<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <div class="row">
      <div class="col-lg-12">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Arama"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="getInverterTypes"
            :search="search"
            :loading="getInverterTypesTableLoading"
            loading-text="Yükleniyor... Bekleyin"
            style="cursor: pointer"
          >
            <template v-slot:[`item.process`]="{ item }">
              <router-link
                :to="{ name: 'inverter-types.edit', params: { id: item.id } }"
              >
                <v-btn title="Düzenle" class="mx-2 bg-warning" fab dark x-small>
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </router-link>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                @click="removeItem(item.id)"
                title="Sil"
                class="mx-2 bg-danger"
                fab
                dark
                x-small
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.id`]="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_INVERTER_TYPES,
  DELETE_INVERTER_TYPE,
} from "@/core/services/store/inverterTypes.module";
import customBreadcrumb from "@/view/custom/breadcrumb";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "inverter-types.index",
  components: {
    customBreadcrumb,
  },
  data() {
    return {
      customBreadcrumb: {
        title: "İnvertör Tipleri",
        type: "Liste",
        pathName: "inverter-types.create",
      },
      search: "",
      headers: [
        { text: "#", align: "left", value: "id" },
        { text: "İşlem", value: "process", align: "left", sortable: false },
        { text: "Marka", value: "brand" },
        { text: "Model", value: "model" },
        { text: "Kanal Sayısı", value: "mppt_channel_count" },
        { text: "Güç", value: "power" },
        { text: "Sil", value: "delete", align: "right", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "breadcrumbs",
      "pageTitle",
      "getInverterTypes",
      "getInverterTypesTableLoading",
    ]),
  },
  methods: {
    removeItem(id) {
      this.$store.dispatch(DELETE_INVERTER_TYPE, id).then((res) => {
        this.$store.dispatch(GET_INVERTER_TYPES);
      });
    },
  },
  created() {
    this.$store.dispatch(GET_INVERTER_TYPES);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "İnvertör Tipleri", icon: "flaticon-dashboard" },
    ]);
  },
};
</script>

<style scoped>
</style>
